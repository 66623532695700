import { createStore } from 'vuex';

export default createStore({
  strict: true,
  state: {
    temp_campaign_state: '',
    modalActive: false,
    videoActive: false,
    videoActiveUrl: '',

    // brand fonts are referenced as classes, found in App.vue
    themes: [
      {
        active: true,
        make: 'chrysler',
        accentColor: '#063e77',
        backgroundColor: '#000000',
        fontRegular: 'chrysler-font-regular',
        fontBold: 'chrysler-font-bold',
        fontColor: '#ffffff',
        btnFontColor: '#ffffff',
        chevron: 'chevron_right_white.png',
      },
      {
        active: false,
        make: 'dodge',
        accentColor: '#d50000',
        backgroundColor: '#000000',
        fontRegular: 'dodge-font-regular',
        fontBold: 'dodge-font-bold',
        fontColor: '#ffffff',
        btnFontColor: '#ffffff',
        chevron: 'chevron_right_white.png',
      },
      {
        active: false,
        make: 'jeep',
        accentColor: '#FFBA00',
        backgroundColor: '#000000',
        fontRegular: 'jeep-font-regular',
        fontBold: 'jeep-font-bold',
        fontColor: '#ffffff',
        btnFontColor: '#000000',
        chevron: 'chevron_right_black.png',
      },
      {
        active: false,
        make: 'wagoneer',
        accentColor: '#15255d',
        backgroundColor: 'white !important',
        fontRegular: 'wagoneer-font-regular',
        fontBold: 'wagoneer-font-bold',
        fontColor: '#000000',
        btnFontColor: '#ffffff',
        chevron: 'chevron_right_white.png',
      },
      {
        active: false,
        make: 'ram',
        accentColor: '#880d00',
        backgroundColor: '#000000',
        fontRegular: 'ram-font-regular',
        fontBold: 'ram-font-bold',
        fontColor: '#ffffff',
        btnFontColor: '#ffffff',
        chevron: 'chevron_right_white.png',
      },
      {
        active: false,
        make: 'fiat',
        accentColor: '#c70606',
        backgroundColor: '#000000',
        fontRegular: 'fiat-font-regular',
        fontBold: 'fiat-font-bold',
        fontColor: '#ffffff',
        btnFontColor: '#000000',
        chevron: 'chevron_right_white.png',
      },
      {
        active: false,
        make: 'alfa_romeo',
        primaryColor: '#8f0c2c',
        backgroundColor: '#000000',
        fontRegular: 'alfa-romero-font-regular',
        fontBold: 'alfa-romeo-font-bold',
        fontColor: '#ffffff',
        btnFontColor: '#000000',
        chevron: 'chevron_right_white.png',
      },
    ],
  },

  getters: {
    activeTheme: state => {
      return state.themes.find(theme => theme.active == true);
    }
  },

  mutations: {

    toggleModal(state) {
      state.windowHeight = document.documentElement.scrollHeight;
      state.modalActive = !state.modalActive;
    },

    setVideoActiveUrl(state, videoActiveUrl) {
      state.videoActiveUrl = videoActiveUrl;
    },

    toggleVideo(state) {
      state.videoActive = !state.videoActive;
    },

    closeEverything(state) {
      state.videoActive = false;
      state.modalActive = false;
      state.videoActiveUrl = '';
    },

    setActiveTheme(state, modelObject) {
      if (modelObject.model.toLowerCase() == 'wagoneer' || modelObject.model.toLowerCase() == 'grand wagoneer') {
        for (let index = 0; index < state.themes.length; ++index) {
          if (state.themes[index].make != 'wagoneer') {
            state.themes[index].active = false;
          } else {
            state.themes[index].active = true;
          }
        }
      } else {
        for (let index = 0; index < state.themes.length; ++index) {
          if (state.themes[index].make != modelObject.make.toLowerCase()) {
            state.themes[index].active = false;
          } else {
            state.themes[index].active = true;
          }
        }
      }
    },

  },

  actions: {
    setActiveTheme(context, modelObject) {
      context.commit('setActiveTheme', modelObject);
    }
  },

  modules: {
  },
});
