<template>

    <div
      id="overlay"
      @click="closeEverything"
      :style="{ height: scrollHeight + 'px' }"
    >
    
    </div>

</template>

<script>
export default {

  name: "the-overlay",

  methods: {
    closeEverything() {
      this.$store.commit("closeEverything");
    },
  },

  computed: {

    scrollHeight() {
      return document.documentElement.scrollHeight;
    },

    videoActive() {
      return this.$store.state.mp4Playing;
    },

    modalActive() {
      return this.$store.state.modalActive ? true : false;
    },
      
  }
};

</script>

<style scoped>

  #overlay {
    position: absolute;
    z-index: 150;
    width: 100%;
    background-color: black;
    opacity: 0.8;
  }

  #overlay:hover {
    cursor: not-allowed;
    cursor: url(../assets/x.png), pointer;
  }

</style>
