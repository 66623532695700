<template>

  <video
    autoplay
    controls
    ref="video"
    :src="videoActiveUrl"
    type='video/mp4'>
  </video>

</template>

<script>
export default {

  name: "the-video",

  methods: {
  },

  computed: {

    videoActiveUrl() {
      return this.$store.state.videoActiveUrl;
    },

    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },
  }
};

</script>

<style scoped>

  video {
    display: flex;
    align-self: center;
    position: fixed;
    width: 100%;
    max-width: 1000px;
    top: 115px;
    border-radius: 5px;
    animation: zoomFadeIn 500ms;
    -webkit-animation: zoomFadeIn 500ms;
    -moz-animation: zoomFadeIn 500ms;
    -o-animation: zoomFadeIn 500ms;
    -ms-animation: zoomFadeIn 500ms;
    box-shadow: 0 0px 90px rgb(0 0 0 / 1);
    z-index: 200;
  }

  @keyframes zoomFadeIn {
    0% {opacity:0; transform: scale(0.7);}
    100% {opacity:1; transform: scale(1);}
  }

  @-moz-keyframes zoomFadeIn {
    0% {opacity:0; transform: scale(0);}
    100% {opacity:1; transform: scale(1);}
  }

  @-webkit-keyframes zoomFadeIn {
    0% {opacity:0; transform: scale(0);}
    100% {opacity:1; transform: scale(1);}
  }

  @-o-keyframes zoomFadeIn {
    0% {opacity:0; transform: scale(0);}
    100% {opacity:1; transform: scale(1);}
  }

  @-ms-keyframes zoomFadeIn {
    0% {opacity:0; transform: scale(0);}
    100% {opacity:1; transform: scale(1);}
  }

</style>
