<template>

  <div
    :style="{'background-color': this.$store.getters.activeTheme.backgroundColor}"
    id="container"
  >

    <the-video v-if="videoActive" id=""></the-video>
    <the-overlay
      v-if="modalActive"
      @click="closeEverything"
      style="overflow-y: hidden; min-height: 2000px;"
      v-bind:style="{ height: scrollHeight + 'px' }"
    />

    <!-- container inner -->
    <div id="container-inner">
      <router-view/>
    </div>
    <!-- end container inner -->

  </div>

</template>

<script>

import TheVideo from '@/components/TheVideo.vue';
import TheOverlay from '@/components/TheOverlay.vue';

export default {
  name: 'app',

  components: {
    TheVideo,
    TheOverlay,
  },

  data() {
    return {

    };
  },

  methods: {

  },

  computed: {

    scrollHeight() {
      return document.documentElement.scrollHeight;
    },

    videoActive() {
      return this.$store.state.videoActiveUrl;
    },

    modalActive() {
      return this.$store.state.modalActive ? true : false;
    },

    activeTheme() {
      return this.$store.state.getters.activeTheme;
    }

  }

}

</script>

<style lang="less">

  html body div input {
    box-sizing: border-box;
  }

  // roboto is our default, used for most copy
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap.css');

  // brand-specific fonts are used in some spots - like headlines
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;600&display=swap.css');
  @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;600&display=swap.css');
  @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;600&display=swap.css');
  @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap.css');
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap.css');
  @import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;600&display=swap.css');
  @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap.css');
 
  .chrysler-font-regular {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  .chrysler-font-bold {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 600;
  }

  .jeep-font-regular {
    font-family: 'Heebo', sans-serif;
    font-weight: 400;
  }

  .jeep-font-bold {
    font-family: 'Heebo', sans-serif;
    font-weight: 600;
  }

  .wagoneer-font-regular {
    font-family: 'EB Garamond', serif;
    font-weight: 400;
  }

  .wagoneer-font-bold {
    font-family: 'EB Garamond', serif;
    font-weight: 500;
  }

  .dodge-font-regular {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 400;
  }

  .dodge-font-bold {
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 600;
  }

  .ram-font-regular {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 400;
  }

  .ram-font-bold {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
  }

  .fiat-font-regular {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400;
  }

  .fiat-font-bold {
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
  }

  .alfa-romeo-font-regular {
    font-family: 'Sarabun', sans-serif;
    font-weight: 300;
  }

  .alfa-romeo-font-bold {
    font-family: 'Sarabun', sans-serif;
    font-weight: 600;
  }

  @media only screen and (min-width: 1401px) {
    .hide-xxl {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1400px) and (min-width: 993px) {
    .hide-xl {
      display: none !important;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 769px) {
    .hide-lg {
      display: none !important;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 577px) {
    .hide-md {
      display: none !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .hide-sm {
      display: none !important;
    }
  }

  .bg-black {
    background-color: black;
  }

  .bg-black {
    background-color: white;
  }

  body {
    margin: 0px;
  }

  h2 {
    font-size: 1.9em;
    margin-top: 0px;
  }

  h3 {
    font-size: 1.3em;
    margin-top: 0px;
  }

  .w-100 {
    width: 100%;
  }

  .w-40 {
    width: 40%;
  }

  .p-1 {
    padding: 1em;
  }

  .p-2 {
    padding: 2em;
  }

  .mt-1 {
    margin-top: 1em;
  }

  .mt-2 {
    margin-top: 2em;
  }

  .mb-1 {
    margin-bottom: 1em;
  }

  .mb-2 {
    margin-bottom: 2em;
  }

  .d-flex {
    display: flex;
  }

  .flex-row {
    flex-direction: row;
  }

  .flex-col {
    flex-direction: column;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .text-white {
    color: white;
  }

  .text-center {
    text-align: center;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  #container {
    width: 100%;
    flex-direction: column;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  #app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
  }

  #container-inner {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  .d-flex {
    display: flex !important;
  }

  .d-none {
    display: none !important;
  }

  .p-absolute {
    position: absolute !important;
  }

  // placeholder animation
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
  .loading {
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    animation: loading 1.5s ease-in-out infinate;
  }
  // end placeholder animation

</style>
