import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(VueGtag, {
  config: { id: 'G-PQ368LRQJM' }
});
app.mount('#app');
