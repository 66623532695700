import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Forward.vue')
  },
  {
    path: '/model-select',
    name: 'ModelSelect',
    component: () => import('../views/ModelSelect.vue'),
    beforeEnter: (to) => {
      if (to.query.d == '') {
        router.push('/not-found');
      }
    },
    props: (route) => ({ query: route.query.q }),
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import('../views/Videos.vue'),
    beforeEnter: (to) =>
    {
      // do we have a dealerCode?
      if (!to.query.d) {
        router.push('/not-found');
      }
      // do we have a model code?
      if (!to.query.m) {
        router.push('/model-select?d=' + to.query.d);
      }
    },
    props: (route) => ({ query: route.query.q }),
  },
  {
    path: '/enroll',
    name: 'Enroll',
    component: () => import('../views/Enroll.vue'),
    props: (route) => ({ query: route.query.q }),
  },
  {
    path: '/assets',
    name: 'AssetExplorer',
    component: () => import('../views/Assets.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'DealerNotFound',
    component: () => import('../views/NotFound.vue'),
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes,
});

export default router;